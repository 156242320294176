import React from "react";
import { Link } from "gatsby";

const ListLink = props => (
    <li
        className="main-navigation__list"
        style={{ display: "inline-block", marginRight: "1rem" }}>
        <Link
            className="main-navigation__link"
            style={{ textDecoration: "none", color: "white" }}
            activeStyle={{ fontWeight: "bold", color: "#FFFFBD" }}
            to={props.to}>
            {props.children}
        </Link>
    </li>
);

const FooterLink = props => (
    <li
        className="footer-naigation__list"
        style={{ display: "inline-block", margin: "0rem 1rem" }}>
        <a style={{ textDecoration: "none", color: "white" }} href={props.to}>
            {props.toText}
        </a>
    </li>
);

export default ({ children }) => (
    <div style={{ margin: "0 auto" }}>
        <header
            style={{
                marginBottom: "2.5rem",
                backgroundColor: "#1A56A2",
                padding: "3rem 1rem",
                boxShadow: "0px 1.5px grey"
            }}>
            <nav className="main-navigation">
                <Link
                    className="main-navigation__link"
                    to="/"
                    style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: "white"
                    }}>
                    <h3 style={{ display: "inline" }}>David Weik</h3>
                </Link>
                <ul
                    aria-label="Primary Menu"
                    className="main-navigation__list"
                    style={{ margin: "0", listStyle: "none", float: "right" }}>
                    <ListLink to="/">Home</ListLink>
                    <ListLink to="/about">About</ListLink>
                    <ListLink to="/contact">Contact</ListLink>
                </ul>
            </nav>
        </header>
        <main
            style={{ margin: "0 auto", maxWidth: 700, alignContent: "center" }}
            className="content"
            aria-labelledby="main-title"
            id="main">
            {children}
        </main>
        <footer
            className="footer"
            style={{
                backgroundColor: "#1A56A2",
                padding: "3rem 1rem",
                textAlign: "center",
                marginTop: "3rem"
            }}>
            <ul
                style={{
                    listStyle: "none",
                    width: "auto",
                    display: "inline-block",
                    margin: "0",
                    padding: "0"
                }}
                className="footer-navigation__list">
                <FooterLink to="https://github.com/Criptic" toText="GitHub" />
                <FooterLink
                    to="https://twitter.com/weik_david"
                    toText="Twitter"
                />
                <FooterLink
                    to="mailto:weik.david@protonmail.com?subject=Contact about Website"
                    toText="E-Mail"
                />
            </ul>
            <p>
                <Link
                    activeStyle={{ fontWeight: "bold", color: "#FFFFBD" }}
                    style={{ textDecoration: "none", color: "white" }}
                    to="/impressum">
                    Impressum & Datenschutz
                </Link>
            </p>
        </footer>
    </div>
);
