import React from "react";

import Navigation from "../components/navigation";

export default () => (
    <Navigation>
        <div>
            <title>David Weik - Impressum</title>
            <div>
                <h1 id="main-title" style={{ textAlign: "center" }}>
                    Impressum & Datenschutz
                </h1>
                <h2>Verantwortlicher nach § 5 Telemediengesetz (TMG)</h2>
                <ul
                    style={{
                        listStyle: "none",
                        padding: "0",
                        margin: "0rem 1rem"
                    }}>
                    <li>David Weik</li>
                    <li>Franz-Marc-Str. 14</li>
                    <li>68519 Viernheim</li>
                    <li>Tel. 0176/84395544</li>
                    <li>Mail: weik.david@protonmail.com</li>
                </ul>
                <h2>Rechtliche Hinweise</h2>
                <h3>§ 1 Haftungsbeschränkung</h3>
                <p style={{ margin: "0rem 1rem" }}>
                    Die Inhalte dieser Website werden mit größtmöglicher
                    Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine
                    Gewähr für die Richtigkeit, Vollständigkeit und Aktualität
                    der bereitgestellten Inhalte. Die Nutzung der Inhalte der
                    Website erfolgt auf eigene Gefahr des Nutzers. Namentlich
                    gekennzeichnete Beiträge geben die Meinung des jeweiligen
                    Autors und nicht immer die Meinung des Anbieters wieder. Mit
                    der reinen Nutzung der Website des Anbieters kommt keinerlei
                    Vertragsverhältnis zwischen dem Nutzer und dem Anbieter
                    zustande.
                </p>
                <h3>§ 2 Externe Links</h3>
                <p style={{ margin: "0rem 1rem" }}>
                    Diese Website enthält Verknüpfungen zu Websites Dritter
                    („externe Links“). Diese Websites unterliegen der Haftung
                    der jeweiligen Betreiber. Der Anbieter hat bei der
                    erstmaligen Verknüpfung der externen Links die fremden
                    Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
                    bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
                    ersichtlich. Der Anbieter hat keinerlei Einfluss auf die
                    aktuelle und zukünftige Gestaltung und auf die Inhalte der
                    verknüpften Seiten. Das Setzen von externen Links bedeutet
                    nicht, dass sich der Anbieter die hinter dem Verweis oder
                    Link liegenden Inhalte zu Eigen macht. Eine ständige
                    Kontrolle der externen Links ist für den Anbieter ohne
                    konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei
                    Kenntnis von Rechtsverstößen werden jedoch derartige externe
                    Links unverzüglich gelöscht.
                </p>
                <h3>§ 3 Urheber- und Leistungsschutzrechte</h3>
                <p style={{ margin: "0rem 1rem" }}>
                    Die auf dieser Website veröffentlichten Inhalte unterliegen
                    dem deutschen Urheber- und Leistungsschutzrecht. Jede vom
                    deutschen Urheber- und Leistungsschutzrecht nicht
                    zugelassene Verwertung bedarf der vorherigen schriftlichen
                    Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
                    Dies gilt insbesondere für Vervielfältigung, Bearbeitung,
                    Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
                    von Inhalten in Datenbanken oder anderen elektronischen
                    Medien und Systemen. Inhalte und Rechte Dritter sind dabei
                    als solche gekennzeichnet. Die unerlaubte Vervielfältigung
                    oder Weitergabe einzelner Inhalte oder kompletter Seiten ist
                    nicht gestattet und strafbar. Lediglich die Herstellung von
                    Kopien und Downloads für den persönlichen, privaten und
                    nicht kommerziellen Gebrauch ist erlaubt. Die Darstellung
                    dieser Website in fremden Frames ist nur mit schriftlicher
                    Erlaubnis zulässig.
                </p>
                <h3>§ 4 Besondere Nutzungsbedingungen</h3>
                <p style={{ margin: "0rem 1rem" }}>
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser
                    Website von den vorgenannten Paragraphen abweichen, wird an
                    entsprechender Stelle ausdrücklich darauf hingewiesen. In
                    diesem Falle gelten im jeweiligen Einzelfall die besonderen
                    Nutzungsbedingungen.
                </p>
                <h2>Datenschutz</h2>
                <p style={{ margin: "0rem 1rem" }}>
                    Nachfolgend möchten wir Sie über unsere Datenschutzerklärung
                    informieren. Sie finden hier Informationen über die Erhebung
                    und Verwendung persönlicher Daten bei der Nutzung unserer
                    Webseite. Wir beachten dabei das für Deutschland geltende
                    Datenschutzrecht. Sie können diese Erklärung jederzeit auf
                    unserer Webseite abrufen. Wir weisen ausdrücklich darauf
                    hin, dass die Datenübertragung im Internet (z.B. bei der
                    Kommunikation per E-Mail) Sicherheitslücken aufweisen und
                    nicht lückenlos vor dem Zugriff durch Dritte geschützt
                    werden kann. Die Verwendung der Kontaktdaten unseres
                    Impressums zur gewerblichen Werbung ist ausdrücklich nicht
                    erwünscht, es sei denn wir hatten zuvor unsere schriftliche
                    Einwilligung erteilt oder es besteht bereits eine
                    Geschäftsbeziehung. Der Anbieter und alle auf dieser Website
                    genannten Personen widersprechen hiermit jeder kommerziellen
                    Verwendung und Weitergabe ihrer Daten.
                </p>
                <h2>Personenbezogene Daten</h2>
                <p style={{ margin: "0rem 1rem 1rem 1rem" }}>
                    Auf dieser Webseite werden keinerlei persönliche Daten über
                    Sie erhoben. Wenn Sie uns kontaktieren erfolgt dies
                    freiwillig und wir werden Ihre Daten nach Beendigung unserer
                    Unterhaltung löschen, sofern Sie nicht expliziet zustimmen,
                    dass wir Ihre Daten behalten können für weitere
                    Kommunikation in der Zukunft.
                </p>
            </div>
        </div>
    </Navigation>
);
